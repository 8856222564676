import React from "react";
import {Globe, Headphones, Linkedin, Mail, Twitter} from "react-feather";
import { getOpacityFromBottomTransition } from "../lib/Transitions";

interface IProps {
  inView: boolean;
  email?: string;
  linkedin?: string;
  twitter?: string;
  website?: string;
  userName: string;
  music?: string;
}

const Socials = (props: IProps) => {
  return (
    <>
      {props.email && (
        <a
          target={"_blank"}
          rel="noreferrer"
          aria-label={"Write an email to " + props.userName}
          href={"mailto:" + props.email}
          className={"text-white hover:text-brand-pink"}
        >
          <Mail
            className={
              "stroke-brand-pink hover:scale-110 " +
              getOpacityFromBottomTransition(props.inView)
            }
          ></Mail>
        </a>
      )}
      {props.linkedin && (
        <a
          target={"_blank"}
          rel="noreferrer"
          aria-label={"Open Linkedin of " + props.userName}
          href={props.linkedin}
          className={"text-white hover:text-brand-pink"}
        >
          <Linkedin
            className={
              "stroke-brand-pink hover:scale-110 " +
              getOpacityFromBottomTransition(props.inView)
            }
          ></Linkedin>
        </a>
      )}
      {props.twitter && (
        <a
          target={"_blank"}
          rel="noreferrer"
          href={props.twitter}
          aria-label={"Open twitter of " + props.userName}
          className={"text-white hover:text-brand-pink"}
        >
          <Twitter
            className={
              "stroke-brand-pink hover:scale-110" +
              getOpacityFromBottomTransition(props.inView)
            }
          ></Twitter>
        </a>
      )}
      {props.website && (
        <a
          href={props.website}
          target={"_blank"}
          rel="noreferrer"
          aria-label={"Open website of " + props.userName}
          className={"text-white hover:text-brand-pink"}
        >
          <Globe
            className={
              "stroke-brand-pink hover:scale-110 " +
              getOpacityFromBottomTransition(props.inView)
            }
          ></Globe>
        </a>
      )}
       {props.music && (
        <a
          href={props.music}
          target={"_blank"}
          rel="noreferrer"
          aria-label={"Open website of " + props.userName}
          className={"text-white hover:text-brand-pink"}
        >
          <Headphones
            className={
              "stroke-brand-pink hover:scale-110 " +
              getOpacityFromBottomTransition(props.inView)
            }
          ></Headphones>
        </a>
      )}
    </>
  );
};

export default Socials;
