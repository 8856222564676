import React from "react";
import Image from "next/image";
import BookACall from "./BookACall";
import {useInView} from "react-intersection-observer";
import Socials from "./Socials";

interface IProps {
    slogan: string;
    image: any;
    title: string;
    className?: string;
    email?: string;
    twitter?: string;
    linkedin?: string;
    website?: string;
    music?: string;
    textTranslationDireciton: string;
    imageTranslationDirection: string;
    children: any
}

const Member = (props: IProps) => {
    const memberImageInView = useInView({
        triggerOnce: true,
        rootMargin: "-40% 0px",
    });

    const memberMobileImageInView = useInView({
        triggerOnce: true,
        rootMargin: "-40% 0px",
    });
    const memberTextInView = useInView({
        triggerOnce: true,
        rootMargin: "-40% 0px",
    });

    const socialsInView = useInView({
        triggerOnce: true,
        rootMargin: "-40% 0px",
    });

    return (
        <div
            ref={memberTextInView.ref}
            className={
                "flex h-full w-full flex-col   overflow-hidden " + props.className
            }
        >
            <div
                className={
                    " translate-transform transform-opacity flex flex-col items-center justify-center  text-center duration-500  md:w-1/2 lg:px-8 " +
                    (!memberTextInView.inView &&
                        props.textTranslationDireciton + " opacity-0")
                }
            >
                <div
                    className={
                        "bg-gradient-to-l from-brand-pink to-brand-turquoise bg-clip-text p-2  pt-4 font-molle text-xl font-extrabold text-transparent sm:text-3xl md:bg-gradient-to-r  lg:mt-8 lg:text-4xl"
                    }
                >
                    {props.slogan}
                </div>

                <div
                    className={
                        " md:mt-20 bg-gradient-to-l from-brand-pink to-brand-turquoise  bg-clip-text  font-mono  text-3xl font-extrabold text-transparent text-white sm:text-4xl md:bg-gradient-to-r lg:mt-8 lg:text-5xl"

                    }
                >
                    {props.title}
                </div>
                <div ref={memberMobileImageInView.ref} className={"w-full md:hidden "}>
                    <Image
                        src={props.image}
                        alt={props.title}
                        width={1024}
                        height={1920}
                        className={
                            "duration-500 " +
                            (!memberMobileImageInView.inView &&
                                props.imageTranslationDirection + " opacity-0")
                        }
                    />
                </div>

                <div className={" shadowedText mb-8 mt-4 text-white  text-center lg:mt-14"}>
                    {props.children}
                </div>
                <div
                    ref={socialsInView.ref}
                    className={"flex w-full flex-row justify-evenly"}
                >
                    <Socials
                        inView={socialsInView.inView}
                        email={props.email}
                        twitter={props.twitter}
                        linkedin={props.linkedin}
                        website={props.website}
                        userName={props.title}
                        music={props.music}
                    />
                </div>
                <div
                    className={
                        "mt-8 transition-opacity delay-500 duration-1000  " +
                        (!socialsInView.inView && " opacity-0  ")
                    }
                >
                    <BookACall className={"text-white"}/>
                </div>
            </div>
            <div ref={memberImageInView.ref} className={"md:w-1/2 hidden md:block "}>
                <Image
                    src={props.image}
                    alt={props.title}
                    width={1024}
                    height={1920}
                    className={
                        "duration-500 " +
                        (!memberImageInView.inView &&
                            props.imageTranslationDirection + " opacity-0")
                    }
                />
            </div>
        </div>
    );
};

export default Member;
